import React from "react"
import SEO from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import Callout from "../components/callout";
import ArticleTile from "../components/article-tile";
import Logo from "../images/logo.kdc-small.svg";
import { Link, graphql } from "gatsby"

export default class Blog extends React.Component {
    render() {
        let global = this.props.data.contentfulWebsite;
        return (
            <Layout pathname={this.props.location.pathname}>
                <SEO metaTitle={`${this.props.pageContext.blog ? this.props.pageContext.blog.name : global.allBlogsTitle} - ${global.title}`} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
                <div className="o-wrapper o-wrapper--small o-content o-container o-content-container">
                    <img className="c-logo c-logo--small" src={Logo} alt={global.title}></img>
                    <div className="o-child-spacer o-child-spacer--small">
                        <Link to="/blog/all" className={`c-tab ${this.props.pageContext.blog ? '' : 'c-tab--active'}`}>{global.allBlogsTitle}</Link>
                        {this.props.pageContext.blogs.map((blog, index) => {
                            return (
                                <Link key={index} to={`/blog/${blog.slug}`} className={`c-tab ${this.props.pageContext.blog && this.props.pageContext.blog.slug === blog.slug ? 'c-tab--active' : ''}`}>{blog.name}</Link>
                            )
                        })}
                    </div>
                    <div className="o-flex o-flex--quarter o-flex--align-start o-content-spacer o-content-spacer--small">
                        {this.props.pageContext.articles.map((article, index) => {
                            return (
                                <div key={index} className={index === 0 ? 'o-wrapper o-wrapper--xsmall u-full-width' : ''}>
                                    <ArticleTile article={article} readMore={global.articleCtaText} full={index === 0} />
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="o-content-container">
                    {this.props.pageContext.blog ? 
                        this.props.pageContext.blog.cards.map((callout, index) => <Callout key={index} callout={callout} />) : 
                        this.props.data.allContentfulCard.nodes.map((callout, index) => <Callout key={index} callout={callout} />)
                    }
                </div>
            </Layout>
        );
    };
}
export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "Kneading Dough Canada"}) {
        author
        description
        title
        articleCtaText
        allBlogsTitle
        shareImage {
            file {
                url
            }
        }
    }
    allContentfulCard(filter: {isDefault: {eq: "Yes"}}) {
        nodes {
            buttonText
            url
            title
            disclaimer {
                raw
            }
            subtitle
        }
    }
}
`;
